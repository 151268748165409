import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import { authGuard } from "@/auth/guard";
import SupplierReportList from "@/views/Ecommerce/Reports/SupplierReportList";

const OrderList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderList.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Orders/OrderDetails.vue"
  );

const RefundList = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Ecommerce/Refunds/RefundList.vue"
  );

const Maintenance = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/Maintenance.vue"
        );


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/orders",
  },
  {
    path: "/orders",
    component: DashboardLayout,
    beforeEnter: authGuard,

    children: [
      {
        path: "",
        name: "Details & Shipping",
        component: OrderList,
        meta: {
          groupName: "Orders",
        },
      },
      {
        path: ":orderid",
        name: "Details",
        component: OrderDetails,
        props: true,
        meta: {
          groupName: "Orders",
        },
      },
    ],
  },
  {
    path: "/refunds",
    component: DashboardLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "Refunds",
        component: RefundList,
        meta: {
          groupName: "Orders",
        },
      },
    ],
  },
  {
    path: "/reports",
    component: DashboardLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: "vendors",
        name: "Vendors",
        component: SupplierReportList,
        meta: {
          groupName: "Reports",
        },
      },
    ],
  },
  {
    path: "/maintenance",
    name: "maintenance",
    beforeEnter: authGuard,
    component: Maintenance,
  },
  {
    path: "*",
    name: "no_found",
    redirect: "/orders",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
