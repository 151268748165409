import moment from "moment";
import Request from "axios-request-handler";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

const MAX_INFO_LINES = 5;

class Task {
  static baseURL;

  constructor({ task_id, name, msg, icon, params }) {
    this.task_id = task_id;
    this.name = name;
    this.msg = msg;
    this.date = new moment();
    this.icon = icon;
    this.ready = false;
    this.watcher = null;
    this.result = null;
    this.error = "";
    this.completed = false;
    this.createMsgFromParams(params);
  }

  createMsgFromParams(params) {
    if (!(params instanceof URLSearchParams)) {
      return;
    }
    let msg = "";
    let lines = 0;
    for (var pair of params.entries()) {
      lines++;
      if (lines >= MAX_INFO_LINES) {
        msg = msg + "...";
        break;
      }
      msg = msg + pair[0].toUpperCase() + " " + pair[1] + "<br/>";
    }

    this.msg = msg;
  }

  track({ accessToken }) {
    let config = {
      method: "get",
      url: "tasks/" + this.task_id,
      lockable: true,
      cancelable: false,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    this.call({ config: config });
  }

  call({ config }) {
    const request = new Request(Task.baseURL + config.url, config);
    request.poll(2000).get((response) => {
      let data = response.data;
      if (!("task_status" in data) || !("task_result" in data)) {
        this.error = "Invalid response";
        return false;
      }
      let status = data.task_status;
      if (this.result) {
        return false;
      }
      if (status === "SUCCESS") {
        this.ready = true;
        this.completed = true;
        if (!validURL(data.task_result)) {
          this.error = "Empty response";
          return false;
        }
        this.result = data.task_result;
        console.log(this.result);
        let download = document.createElement("a");
        download.href = data.task_result;
        download.download = "download";
        download.click();

        return false;
      } else if (status === "FAILED") {
        this.completed = true;
        this.error = data.task_result;
        return false;
      } else if (status !== "PENDING") {
        this.completed = true;
        this.error = "Invalid response status: " + status;
        return false;
      }
    });
  }
}

export default Task;
