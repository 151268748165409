<template>
  <div class="text--secondary text-subtitle-2 text-sm">
    <v-icon :size="icon_size">{{ icon("fas fa-sync") }} mr-2</v-icon>
    <a @click="click" class="text-decoration-underline">Refresh</a>
  </div>
</template>

<script>
export default {
  name: "RefreshButton",
  props: {
    loading: {
      required: false,
      default() {
        return false;
      },
    },
    icon_size: {
      required: false,
      type: Number,
      default() {
        return 13;
      },
    },
  },
  methods: {
    click() {
      if(this.loading) return;
      this.$emit("click", 1);
    },
    icon(nonAnimatedIcon) {
      if (this.loading) return nonAnimatedIcon + " fa-spin";
      return nonAnimatedIcon;
    },
  },
};
</script>

<style scoped></style>
