/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  
* Copyright 2021 River Shark Technologies (https://rivershark.tech)

Coded by www.rivershark.tech

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import Axios from "axios";
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';


Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

//Datetime plugin
require("flatpickr/dist/themes/material_red.css");
Vue.use(VueFlatPickr);


//Axios global
const axiosConfig = {
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 12000,
};
Vue.prototype.$http = Axios.create(axiosConfig);

//Auth 0 plugin
import { Auth0Plugin } from "./auth";
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const scope = process.env.VUE_APP_AUTH0_SCOPES;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  scope,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});




new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
