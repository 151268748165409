<template>
  <div>
    <div @click="openDateTime" :class="{ 'datetime-container': !disabled }">
      <div :class="name">
        <div class="mb-1">
          <span v-if="date">
            <span class="font-weight-bold">
              <v-icon>fas fa-calendar-day mr-2</v-icon>From:</span
            >
            {{ dateFrom || "Select date" }} <br />
            <span class="font-weight-bold">
              <v-icon>fas fa-calendar-day fa-flip-horizontal mr-2</v-icon
              >To:</span
            >
            {{ dateTo || "Select date" }}
          </span>
        </div>
        <span class="text-sm-caption">
          {{ "Picked range: " + date_msg }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import moment from "moment";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
export default {
  name: "DatePicker",
  props: {
    name: {
      type: String,
      required: true,
    },
    initial_dates: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      date: null,
      date_range: this.initial_dates,
      datepicker: null,
      today: new Date().setHours(24, 0, 0, 0),
      selected_dates: this.initial_dates,
      dateTo: "",
      dateFrom: "",
      config: {
        enableTime: true,
        mode: "range",
        dateFormat: "h:i K d/m/y",
        defaultDate: [],
        defaultHour: 0,
        disable: [],
        defaultMinute: 0,
        shorthandCurrentMonth: true,
        nextArrow: '<i class="fas fa-chevron-right fa-1x"></i>',
        prevArrow: '<i class="fas fa-chevron-left fa-1x"></i>',
        plugins: [
          new confirmDatePlugin({
            confirmIcon: "<i class='fa fa-check ml-2'></i>", // your icon's html, if you wish to override
            confirmText: "SELECT ",
          }),
        ],
      },
    };
  },
  created() {
    this.$nextTick(function () {
      this.initializeDatePicker();
    });
  },
  computed: {
    date_msg() {
      if (this.date_range) {
        return moment
          .duration(moment(this.date_range[0]).diff(this.date_range[1]))
          .humanize();
      }
    },
  },
  methods: {
    initializeDatePicker() {
      let calArea = document.querySelector("." + this.name);
      if (calArea === null) {
        return;
      }
      if (this.datepicker !== null) {
        return;
      }
      this.config.disable = [
        (date) => {
          if (date > this.today) return true;
          return false;
        },
      ];
      let dates = [];
      this.initial_dates.forEach((date) => {
        dates.push(moment(date).toDate());
      });
      this.config.defaultDate = dates;

      this.datepicker = flatpickr("." + this.name, this.config);
      this.date = this.datepicker.input.value;
      this.setToFrom();

      this.datepicker.config.onChange.push(() => {
        this.date = this.datepicker.input.value;
        this.setToFrom();
      });
      this.datepicker.config.onChange.push((selectedDates) => {
        if (selectedDates.length === 2) {
          let dates = {};
          dates.before = selectedDates[1];
          dates.after = selectedDates[0];
          if (dates.after && dates.before) {
            this.date_range = selectedDates;
            this.$emit("selected-dates", dates);
          }
        }
      });

    },

    setToFrom() {
      if (!this.date) return;
      let s = this.date.split("to");
      if (s.length >= 1) {
        this.dateFrom = s[0];
      }
      if (s.length === 2) {
        this.dateTo = s[1];
      }
    },

    openDateTime() {
      if (this.disabled) return;
      this.datepicker.open();
    },
  },
};
</script>

<style lang="css" scoped>
.v-input >>> label {
  font-size: 1.04em;
}

.datetime-container {
  cursor: pointer;
}
</style>

<style>
.flatpickr-calendar {
  background-color: white !important;
}

.flatpickr-confirm {
  margin: 10px;
  background-color: white !important;
  cursor: pointer;
  font-size: 17px;
}

.flatpickr-confirm:hover {
  font-size: 17.5px;
}
</style>
