<template>
  <div>
  <v-overlay :value="state.loading" opacity="0.4" z-index="10">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
    </v-overlay>

    <router-view ></router-view>

  </div>
</template>

<script>
import Task from '@/util/Task'
import {EventBus} from "@/event-bus";
export default {
  data() {
    return {
      state: {
        loading: false,
        checkedStatus : false
      },
    }
  },
  async created() {

    Task.baseURL = this.$http.defaults.baseURL;

    EventBus.$on('loading', (state) => {
      this.state.loading = state.loading;
    });
    await this.checkMode();





  },
  methods:{
    async checkMode(){
      // Check if in maintenance
      try{
        if(this.checkedStatus) return;
        this.checkedStatus = true;
        await this.$http({
          method: 'get',
          url: '/info/'
        })
      }catch (error){
        if(error.response.status === 503){
          this.$router.push('/maintenance')
        }
      }
    }
  }
};
</script>
