const states = {
    'AK': 'Akkar',
    'AL': 'Aley',
    'BD': 'Baabda',
    'BK': 'Baalbek',
    'BN': 'Batroun',
    'BC': 'Bcharre',
    'BT': 'Beirut',
    'BA': 'Bekaa',
    'BJ': 'Bint Jbeil',
    'CF': 'Chouf',
    'DN': 'Dinniyyeh',
    'HB': 'Hasbaya',
    'HM': 'Hermel',
    'JB': 'Jbeil',
    'JZ': 'Jezzine',
    'KS': 'Kesserwen',
    'KR': 'Koura',
    'MJ': 'Marjayoun',
    'MN': 'Matn',
    'NB': 'Nabatiyeh',
    'RH': 'Rachayya',
    'SD': 'Sidon',
    'TR': 'Tripoli',
    'TY': 'Tyre',
    'ZH': 'Zahle',
    'ZG': 'Zgharta',
}

export default states;
