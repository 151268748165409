<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="orders"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="totalItems"
          loading-text="Loading items.."
          disable-filtering
          :no-data-text="noData"
        >
          <template v-slot:top v-if="authorized">
            <v-toolbar flat height="120">
              <v-row>
                <v-col lg="3" md="5">
                  <DatePicker
                    name="orders-date"
                    @selected-dates="getSupplierReport"
                    :initial_dates="[dates.before, dates.after]"
                  />
                </v-col>

                <v-col cols="5">
                  <v-text-field
                    v-on:keyup.enter="getSupplierReport()"
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    clearable
                    solo
                    height="20"
                    v-model="search"
                    type="text"
                    placeholder="Search by item name or ID"
                    append-icon="fas fa-search"
                    @click:append="getSupplierReport()"
                    @click:clear="getSupplierReport()"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size=".875rem"
                        class="align-center"
                        >fas fa-tag mb-2
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="text-right">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-default btn-outline-default
                      py-2
                      px-6
                      me-2
                    "
                    color="transparent"
                    small
                    @click="export_file"
                  >
                    <v-icon>ni ni-archive-2 me-1</v-icon>

                    Export Report
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-toolbar flat height="50">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="vendorItems"
                    v-model="selectedVendors"
                    :disabled="loadingVendors"
                    prepend-icon="fas fa-store-alt"
                    multiple
                    clearable
                    chips
                    color="green"
                    deletable-chips
                    small-chips
                    no-data-text="No vendors matching this criteria"
                    item-color="green"
                    label="Select vendors"
                    placeholder="Search for vendors by their store name"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-toolbar flat height="20" class="mb-5 mt-2">

              <v-row>
                <v-col class="text-left">
                  <RefreshButton :loading="loading" @click="getSupplierReport(null)"></RefreshButton>
                </v-col>

              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:item.vendor="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.vendor
            }}</span>
          </template>
          <template v-slot:item._id="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item._id
            }}</span>
          </template>
          <template v-slot:item.product_name="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.product_name
            }}</span>
          </template>
          <template v-slot:item.arabic_name="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.arabic_name || "-"
            }}</span>
          </template>
          <template v-slot:item.product_sku="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.product_sku
            }}</span>
          </template>

          <template v-slot:item.qty="{ item }">
            <span class="my-2 text-md text-body font-weight-bold">{{
              item.qty
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-md">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="16"
              max="2"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              v-show="!loading"
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#00AD4D"
              v-model="page"
              @next="page++"
              @previous="page--"
              :length="totalPages"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import states from "@/util/States";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import { EventBus } from "@/event-bus";
import Task from "@/util/Task";
import RefreshButton from "@/components/RefreshButton";

export default {
  name: "SupplierReportTable",
  components: {RefreshButton, DatePicker },
  data() {
    return {
      states,
      page: 1,
      pageCount: 0,
      itemsPerPage: 16,
      totalPages: 0,
      totalItems: 0,
      vendorItems: [],
      selectedVendors: [],
      params: new URLSearchParams(),
      loading: false,
      moment,
      loadingVendors: false,
      pagination: {},
      dates: {
        after: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
        before: moment().endOf("day").format("YYYY-MM-DD HH:mm"),
      },
      orders: [],
      authorized: true,
      statusSelector: [],
      typeSelector: [],
      status: ["processing", "failed", "on-hold", "refunded", "pending"],
      selected_status: ["processing"],
      current_status: [],
      search: "",
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      headers: [
        {
          text: "Vendor",
          align: "start",
          sortable: false,
          value: "vendor",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Item ID",
          align: "center",
          sortable: false,
          value: "_id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Item Name (EN)",
          value: "product_name",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Item Name (AR)",
          value: "arabic_name",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "SKU",
          value: "product_sku",
          sortable: false,
          align: "center",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Quantity",
          value: "qty",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getVendors();
  },

  methods: {
    async getVendors() {
      const accessToken = await this.$auth.getTokenSilently();
      try {
        this.loadingVendors = true;
        let response = await this.$http({
          method: "get",
          url: "/vendors/",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (response.status !== 200) return;
        if (!response.data) return;
        let items = [];
        response.data.forEach((vendor) => {
          let item = {};
          item.text = vendor.name;
          item.value = vendor.id;
          items.push(item);
        });
        this.vendorItems = items;
        console.log(this.vendorItems);
      } catch (error) {
      } finally {
        this.loadingVendors = false;
      }
    },

    async getSupplierReport(dates) {
      if(this.loading) return;
      this.loading = true;
      this.orders = [];
      let isSearch = false;
      //token
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      // Page filter
      params.append("limit", this.itemsPerPage);
      // Date filter
      if (dates) {
        this.dates = dates;
      }
      let selected_dates = this.dates;

      this.selectedVendors.forEach((vendor_id) => {
        params.append("vendor_ids", vendor_id);
      });

      // Search
      if (this.search) {
        this.search = this.search.trim();
        if (this.isNumeric(this.search)) {
          params.append("product_id", this.search);
        } else {
          params.append("item_name", this.search);
        }
        isSearch = true;
      }

      if (selected_dates) {
        params.append(
          "before",
          moment(selected_dates.before).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "after",
          moment(selected_dates.after).format("YYYY-MM-DD HH:mm")
        );
      }
      // Check if param is different and reset page and selected item
      if (this.params.toString() !== params.toString()) {
        this.page = 1;
        this.params = new URLSearchParams(params.toString());
      }

      // Page filter
      params.append("page", this.page);
      try {
        let response = await this.$http({
          method: "get",
          url: "/vendors/report",
          params: params,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (response.status !== 200) return;
        this.orders = response.data;
        this.totalPages = parseInt(response.headers["x-pages"]);
        this.totalItems = parseInt(response.headers["x-total"]);
      } catch (error) {
        if (error.response.status === 403) {
          this.authorized = false;
        }
      } finally {
        this.loading = false;
      }
    },

    async export_file() {
      const accessToken = await this.$auth.getTokenSilently();
      let params = new URLSearchParams();
      this.selectedVendors.forEach((vendor_id) => {
        params.append("vendor_ids", vendor_id);
      });
      if (this.dates) {
        params.append(
          "before",
          moment(this.dates.before).format("YYYY-MM-DD HH:mm")
        );
        params.append(
          "after",
          moment(this.dates.after).format("YYYY-MM-DD HH:mm")
        );
      }

      let task = new Task({
        name: "Vendors Report",
        params: params,
        icon: "fas fa-store-alt",
      });
      EventBus.$emit("addDownload", task);

      try {
        let response = await this.$http({
          method: "get",
          url: "/vendors/report/file",
          params: params,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        task.task_id = response.data.id;
        EventBus.$emit("watch", task);
      } catch (error) {
        task.completed = true;
        task.error =
          "An error has occurred while sending request to server " +
          error.response.data.detail.msg;
      }
    },

    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseInt(str))
      ); // ...and ensure strings of whitespace fail
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getSupplierReport(this.dates);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    itemsPerPage(val) {
      if (Number.isNaN(val)) return;
      if (val <= 0) return;
      setTimeout(() => {
        this.getSupplierReport();
      }, 1000);
    },
    selectedVendors(val) {
      this.getSupplierReport();
    },
  },
  filters: {},

  computed: {
    pages() {
      if (this.items.length === 0) return 0;
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    isProcessing() {
      return this.selected_status.includes("processing");
    },
    noData() {
      if (!this.authorized) {
        return "You are not authorized to view this data";
      }
      return "No data available";
    },
  },
};
</script>

