<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1 mt-1">
      <v-col cols="12" sm="6" class="d-flex">

        <div>
          <v-breadcrumbs class="pb-0 pt-1 px-0">

            <li class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
            <v-breadcrumbs-item class="opacity-5 text-dark">
              {{ $route.meta.groupName }}
            </v-breadcrumbs-item>
            <li class="v-breadcrumbs__divider opacity-5 px-2 text-muted">/</li>
            <v-breadcrumbs-item
              active-class="active-breadcrumb"
              class="no-default-hover text-dark"
            >
              <template v-if="$route.name === 'Dashboard'">Default</template>
              <template v-else>{{ $route.name }}</template>
            </v-breadcrumbs-item>
          </v-breadcrumbs>




          <h6 class="text-h6 font-weight-bolder text-typo mb-0 mt-1">
            {{ $route.name }}
          </h6>

        </div>
        <v-chip
            v-if="isStaging"
            class="ma-2 font-weight-bold"
            color="red"
            text-color="white"

        >
          <v-icon size="16">fas fa-clipboard-list mr-2</v-icon> STAGING SITE
        </v-chip>

      </v-col>



      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center"
        style="text-align: right"
        :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      >

        <v-spacer cols="6"></v-spacer>

        <v-btn
          depressed
          :ripple="false"
          class="font-weight-600 text-body ls-0  pe-0 me-2 text-lowercase"
          color="transparent"
          v-if="this.$auth.user"
        >
          <v-icon size="16">fa fa-user me-sm-2 text-lg</v-icon>
          <span
            class="d-sm-inline font-weight-bold d-none"
            :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
            :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
            >{{this.$auth.user.email}}</span
          >
        </v-btn>
        <v-btn
            depressed
            :ripple="false"
            class="font-weight-600 text-body ls-0 text-capitalize pe-0 me-2"
            color="transparent"
            v-if="!$auth.isAuthenticated" @click="login"
        >
          <v-icon size="16">fas fa-sign-in-alt text-lg</v-icon>
          <span
              class="d-sm-inline font-weight-bold d-none"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
          >Login</span
          >
        </v-btn>
        <v-btn
          depressed
          :ripple="false"
            class="font-weight-600 text-body ls-0 text-capitalize pe-0 me-2"
            color="transparent"
          v-if="this.$auth.isAuthenticated" @click="logout"
        >
          <v-icon size="16">fas fa-sign-out-alt me-sm-2 text-lg</v-icon>
          <span
              class="d-sm-inline font-weight-bold d-none"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
          >Logout</span
          >






        <v-menu
            v-model="openDownloads"
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="300"
          max-width="300"

        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn

                depressed
                :ripple="false"
                class="font-weight-600 text-body ls-0 text-capitalize pe-0 me-2"
                color="transparent"
              v-bind="attrs"
              v-on="on"

            >
              <v-badge overlap v-if="isDownloading > 0"        :content="isDownloading" color="red" left offset-x="4" offset-y="2" bordered
              >

              </v-badge>
              <v-icon size="23" >fas fa-cloud-download-alt text-lg mr-2</v-icon>
              <span
                  class="d-sm-inline font-weight-bold d-none"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"

              >Downloads</span
              >
            </v-btn>


          </template>

          <v-list class="py-0" >

            <v-list-item v-if="tasks.length === 0">
             <v-icon class="mr-2">fas fa-times</v-icon> <span class="text-body-1">No downloads</span>
            </v-list-item>
            <v-list-item
              v-for="(task, i) in tasks"
              :key="i"
              class="pa-4 download-item"
              :class="{'list-item-hover-active':task.ready}"
              @click="download_task_output(task)"
            >


              <v-list-item-avatar
                class="my-0 me-4 border-radius-lg"
                v-if="task.icon"
              >
                <v-icon :size="30"
                >

                  {{task.icon}}
                </v-icon>
              </v-list-item-avatar>



              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6
                        class="text-sm font-weight-normal mb-1 text-typo"
                      >
                        <v-progress-circular
                            size="15"
                            indeterminate
                            color="primary"
                            class="mr-2"
                            width="2"
                            v-if="!task.ready && !task.error"
                        ></v-progress-circular>

                        <v-icon color="green"  v-if="task.ready">
                          fas fa-check mr-2
                        </v-icon>
                        <v-icon color="red"  v-if="task.error">
                          fas fa-times mr-2
                        </v-icon>
                        {{task.name}}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <p class="text-xs text-secondary mb-1 mt-1" v-if="task.error">
                  <v-icon size="12" class="text-danger"
                  >fas fa-exclamation-circle me-1</v-icon
                  >
                <p class="text-xs text-danger mb-1 mt-1" v-html="task.error"></p>
                </p>
                <p class="text-xs text-secondary mb-1 mt-1" v-if="task.msg">
                  <v-icon size="12" class="text-secondary"
                  >fa fa-search me-1</v-icon
                  >
                  <p class="text-xs text-secondary mb-1 mt-1" v-html="task.msg"></p>
                </p>
                <p class="text-xs text-secondary mb-0">
                  <v-icon size="12" class="text-secondary"
                    >fa fa-clock me-1</v-icon
                  >
                  {{ task.date.fromNow()  }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        </v-btn>



        <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-3
            px-0

            rounded-sm
          "
            :class="{
            active: togglerActive,
          }"
            v-if="$vuetify.breakpoint.mobile"
            color="transparent"
            @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
      </v-col>
    </v-row>

  </v-app-bar>
</template>
<script>
import {EventBus} from "@/event-bus";
import Task from "@/util/Task";

export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      tasks: [],
      openDownloads: false,

    };
  },
  created() {
    console.log(this.$auth.user)
    EventBus.$on('addDownload', this.addDownload);
    EventBus.$on('watch', this.handleTaskWatcher);
  },
  computed:{
    isDownloading(){
      return this.tasks.filter(task => !task.completed).length
    },
    isStaging(){
      return this.$http.defaults.baseURL.includes("staging");
    }
  },
  methods: {
    download_task_output(task){
      if(task.ready && task.result){
        let download = document.createElement('a');
        download.href = task.result;
        download.download = 'download';
        download.click();
      }

    },
    addDownload(task){
      this.openDownloads = true;
      this.tasks.unshift(task)
    },
    async handleTaskWatcher(task) {
      console.log("handling task " + task.task_id)
      console.log(task.name)
      if(!task.task_id){
        task.error = "No task id!"
        return;
      }
      const accessToken = await this.$auth.getTokenSilently();
      task.track({accessToken: accessToken})
    },
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    login() {
      this.$auth.loginWithRedirect();
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  }
}
</script>

<style scoped>
.download-item{
  border-top: 0.5px solid black;
}

</style>
